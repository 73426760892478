import { unwrapResult } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import getPlaybackStream, {
  PlaybackStreamNotFoundError,
  PlaybackStreamUnauthorisedError,
} from '@@src/apis/PlaybackApi';
import { useAppDispatch, useAppSelector, useAppStore } from '@@src/hooks/store';
import useIsDirectAccess from '@@src/hooks/useIsDirectAccess';
import { SbsUIVariant } from '@@src/lib/VideoPlayerV2/BitmovinPlayerUI/components/SbsUIContainer';
import OztamTracking from '@@src/lib/VideoPlayerV2/plugins/OztamTracking/OztamTracking';
import { getUserPreferences } from '@@src/lib/VideoPlayerV2/plugins/SaveUserPreferences/SaveUserPreferences';
import { getServerSideRendered } from '@@stores/SettingsStore';
import { getProgressByEntityAsyncThunk } from '@@stores/UserActivityStore';
import { getVideoAsyncThunk } from '@@stores/VideoStore';
import OnDemand from '@@types/OnDemand';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';
import { getVideoPath, videoHasDetailsPage } from '@@utils/ApiV3ItemUtils';
import Logger from '@@utils/logger/Logger';

const enableOztam = typeof process.env.BVAR_OZTAM_ENV !== 'undefined';

/**
 * Determine the URL for the back button.
 *
 * @param video current video object.
 * @param videoIdChangeHistory history of video IDs requested during same session (change from end card or episode picker).
 * @param isDirectAccess whether the current page was accessed directly or by navigating within the website
 * @param isSSR whether it's client or server side rendered
 */
export const getClosePath = (
  video: OnDemand.Video | null,
  videoIdChangeHistory: string[],
  isDirectAccess: boolean,
  isSSR: boolean,
): string => {
  if (isDirectAccess && !videoHasDetailsPage(video)) {
    return '/';
  }

  if (!videoHasDetailsPage(video)) {
    return '';
  }

  if (isSSR || isDirectAccess || videoIdChangeHistory.length > 1) {
    return getVideoPath(video);
  }

  return '';
};

const useGetVideoData = (
  videoId: string,
  language: string,
  userId: string | null,
) => {
  const [data, setData] = useState<{
    playbackStreamData?: PlaybackStreamData;
    video?: OnDemand.Video;
    ozTamSessionId?: string;
    resumePosition?: number;
    uiVariants?: Record<string, SbsUIVariant>;
    closePath?: string;
    error?: Error;
  }>({});

  const dispatch = useAppDispatch();
  const store = useAppStore();

  const isDirectAccess = useIsDirectAccess();
  const serverSideRendered = useAppSelector(getServerSideRendered);

  // This variable is used as a video ID change history, used to determine the back button URL.
  // Using ref because we don't want videoIds to trigger a re-render.
  const videoIdChangeHistory = useRef<string[]>([]);

  useEffect(() => {
    videoIdChangeHistory.current = uniq([...videoIdChangeHistory.current, videoId]);
  }, [videoId]);

  useEffect(() => {
    // if videoId is not a number, we know that it's not valid, so don't even try to call the API
    if (videoId && !/^\d+$/.exec(videoId)) {
      return;
    }

    // need to wait until the user is set before loading the video
    if (userId === undefined) {
      return;
    }

    const ozTamSessionId = enableOztam ? OztamTracking.generateSessionId() : undefined;
    const userPrefs = getUserPreferences();

    const fn = async () => {
      let video;

      try {
        video = await dispatch(
          getVideoAsyncThunk(
            videoId,
            language,
          ),
        );
      } catch (err) {
        Logger.error(err);
        video = null;
      }

      let playbackStreamData;

      try {
        playbackStreamData = await getPlaybackStream(
          videoId,
          language,
          {
            telariaId: (window as any).tvid_cookie_id,
            ozTamSessionId,
            ...(userPrefs?.stLang && { subtitle: userPrefs.stLang }),
          },
        );
      } catch (err: unknown) {
        Logger.error(err);
        setData({
          video,
          error: err as PlaybackStreamUnauthorisedError | PlaybackStreamNotFoundError,
        });
        return;
      }

      let resumePosition;

      if (playbackStreamData.streamType === 'live' || playbackStreamData.entityType === 'CLIP') {
        resumePosition = 0;
      } else {
        try {
          const resumePositionList = await dispatch(getProgressByEntityAsyncThunk({
            entityId: playbackStreamData.entityId,
          })).then(unwrapResult);
          resumePosition = resumePositionList.find((i) => {
            return i.entityId === playbackStreamData.entityId;
          }).seconds ?? 0;
        } catch (err) {
          Logger.error(err);
          resumePosition = 0;
        }
      }

      setData({
        playbackStreamData,
        video,
        ozTamSessionId,
        resumePosition,
        closePath: getClosePath(video, videoIdChangeHistory.current, isDirectAccess, serverSideRendered),
      });
    };

    fn();
  }, [videoId, language, dispatch, store, userId, serverSideRendered, isDirectAccess]);

  return data;
};

export default useGetVideoData;
