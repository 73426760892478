import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import {
  VideoPlayerCustomEvent,
  VideoPlayerEventCallback,
  VideoPlayerEventType, VideoPlayerPlaybackEvent,
  VideoPlayerSeekEvent,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import { AdSnapBackEventType } from '@@src/lib/VideoPlayerV2/plugins/AdSnapBack/AdSnapBackCore';
import { ShowAtPlayTimeEventType } from '@@src/lib/VideoPlayerV2/plugins/Utils/ShowAtPlayTime';
import { VideoPlaybackEventType } from '@@src/lib/VideoPlayerV2/plugins/VideoPlaybackTracking/VideoPlaybackTrackingCore';
import OnDemand from '@@types/OnDemand';

interface PlaybackEvents {
  type: VideoPlayerEventType;
  handler: VideoPlayerEventCallback;
}

class SaveProgressCore {
  private readonly recordCallback: (duration: number, position: number, id: string) => void;
  private video: OnDemand.Video;
  private videoPlayer: VideoPlayer;
  private events: PlaybackEvents[] = [];
  private playerState: 'playing' | 'paused' | 'seeking' = 'paused';
  private seekIssuer: string | undefined;
  private hasContentStarted: boolean = false;

  // @ts-ignore, no need to set until the interval is created
  private autoSaveIntervalId: ReturnType<typeof setInterval>;

  // @ts-ignore, no need to set until the interval is created
  private seekRecordingDelayTimer: ReturnType<typeof setTimeout>;

  private readonly recordInterval: number = 90000;

  public constructor(
    videoPlayer: VideoPlayer,
    video: OnDemand.Video,
    recordCallback: (duration: number, position: number, id: string) => void,
  ) {
    this.videoPlayer = videoPlayer;
    this.video = video;
    this.recordCallback = recordCallback;

    if (process.env.BVAR_PLAYER_PROGRESS_SAVE_INTERVAL) {
      this.recordInterval = parseInt(process.env.BVAR_PLAYER_PROGRESS_SAVE_INTERVAL, 10) * 1000;
    }

    this.events = [
      { type: VideoPlayerEventType.CONTENT_STARTED, handler: this.onContentStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_BREAK_STARTED, handler: this.onAdBreakStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_BREAK_FINISHED, handler: this.onAdBreakFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PLAY, handler: this.onPlay as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PAUSED, handler: this.onPaused as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PLAYBACK_FINISHED, handler: this.onPlaybackFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.SEEK_STARTED, handler: this.onSeekStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.SEEK_FINISHED, handler: this.onSeekFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.CUSTOM_EVENT, handler: this.onCustomEvent as VideoPlayerEventCallback },
    ];

    this.registerEvents();
  }

  private registerEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.on(event.type, event.handler);
    });

    this.videoPlayer.getVideoElement()?.addEventListener('leavepictureinpicture', this.onLeavePip, false);
  };

  private onLeavePip = () => {
    this.record();
  };

  private unRegisterEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.off(event.type, event.handler);
    });

    this.videoPlayer.getVideoElement()?.removeEventListener('leavepictureinpicture', this.onLeavePip, false);
  };

  private record = (overridenPosition?: number) => {
    const positionToSave = overridenPosition || this.videoPlayer.getCurrentContentTime();
    this.recordCallback(this.video.duration, positionToSave, this.video.catalogueId);
  };

  private enableAutoSave = () => {
    clearInterval(this.autoSaveIntervalId);
    this.autoSaveIntervalId = setInterval(() => {
      this.record();
    }, this.recordInterval);
  };

  private disableAutoSave = () => {
    clearInterval(this.autoSaveIntervalId);
  };

  private onContentStarted = () => {
    this.hasContentStarted = true;
    this.record();

    this.enableAutoSave();
  };

  private onAdBreakStarted = () => {
    this.disableAutoSave();
  };

  private onAdBreakFinished = () => {
    this.enableAutoSave();

    if (this.hasContentStarted) {
      const recordOnce = () => {
        this.record();
        this.videoPlayer.off(VideoPlayerEventType.TIME_CHANGED, recordOnce);
      };

      this.videoPlayer.on(VideoPlayerEventType.TIME_CHANGED, recordOnce);
    }
  };

  private onPlay = (event: VideoPlayerPlaybackEvent) => {
    if (!event.isAd && this.playerState === 'paused') {
      this.enableAutoSave();
    }

    this.playerState = 'playing';
  };

  private onPaused = (event: VideoPlayerPlaybackEvent) => {
    const { issuer } = event;

    if (
      (
        issuer?.startsWith('ui')
        && issuer !== 'ui-seek' // recording progress on seek is handled by onSeekStarted
      )
      || issuer === 'playerclose'
    ) {
      this.record(this.videoPlayer.contentTimeForStreamTime(event.time));
      this.playerState = 'paused';
    }

    this.disableAutoSave();
  };

  private onPlaybackFinished = () => {
    this.record();
    this.disableAutoSave();
  };

  private onSeekStarted = (event: VideoPlayerSeekEvent) => {
    const { issuer } = event;
    this.seekIssuer = issuer;

    if (issuer?.startsWith('ui')) {
      this.disableAutoSave();

      // Allows ad snapback event to stop this recording to avoid ad-skipping hack.
      this.seekRecordingDelayTimer = setTimeout(() => {
        this.record(this.videoPlayer.contentTimeForStreamTime(event.seekTarget));
      }, 1000);
    }

    this.playerState = 'seeking';
  };

  private onSeekFinished = () => {
    if (this.seekIssuer?.startsWith('ui')) {
      this.enableAutoSave();
    }
  };

  private onCustomEvent = (event: VideoPlayerCustomEvent) => {
    const { eventName, customData } = event;

    if (eventName === ShowAtPlayTimeEventType.SHOWN_AT_PLAYTIME && customData?.cardName === 'AutoPlayNextEpisode') {
      this.record();
    }

    // Prevent recording of progress at seek if it leads to a midroll to avoid ad-skipping hack.
    if (eventName === AdSnapBackEventType.AD_SNAP_BACK_STARTED) {
      this.disableAutoSave();
      clearTimeout(this.seekRecordingDelayTimer);
    }

    if (eventName === VideoPlaybackEventType.MILESTONE_REACHED && customData?.milestone === 95) {
      this.record();
    }
  };

  public destroy = () => {
    this.disableAutoSave();
    this.unRegisterEvents();
  };
}

export default SaveProgressCore;
