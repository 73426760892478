import { get } from 'lodash';
import { isSafari } from 'react-device-detect';

import '@@src/lib/VideoPlayerV2/Vendors/OzTAMService.min';
import { CastPlayerEventType } from '@@src/lib/VideoPlayerV2/CastPlayer';
import { AdSnapBackEventType } from '@@src/lib/VideoPlayerV2/plugins/AdSnapBack/AdSnapBackCore';
import Logger from '@@utils/logger/Logger';

import type VideoPlayer from '../../VideoPlayer';
import {
  VideoPlayerCustomEvent,
  VideoPlayerEventBase,
  VideoPlayerEventCallback,
  VideoPlayerEventType,
  VideoPlayerSeekEvent,
} from '../../VideoPlayerEventManager';

export type OzTAMServiceConstructor = new(publisherId: string, vendorVersion: string, isProd: boolean, enableDebug: boolean, useHttps: boolean) => OzTAMService;

export interface OzTAMService {
  sessionId: string | null;
  nextSessionId: string | null;
  state: string;
  generateNextSessionID(): string;
  stop(): void;
  startSession(videoId: string, url: string, duration: number, streamType: string): void;
  beginPlayback(
    id: string,
    url: string,
    duration: number,
    getCurrentTime: () => number,
    mediaProperties: any,
    streamType: string
  ): void;
  haltProgress(): void;
  adBegin(): void;
  adComplete(): void;
  resumeProgress(): void;
  complete(): void;
  seekBegin(): void;
  seekComplete(): void;
  PROP_CONNECTION_TYPE: string;
  PROP_GENRE: string;
  PROP_CLASSIFICATION: string;
  PROP_DEMO1: string;
  PROP_CHANNEL: string;
  PROP_ALT_MEDIA_ID: string;
  STATE_PLAYING: string;
  STATE_SEEKING: string;
}

declare global {
  interface Window {
    OzTAMService: OzTAMServiceConstructor;
  }
}

export interface OztamTrackingConfig {
  publisherId: string;
  vendorVersion: string;
  videoId: string;
  duration: number;
  isLiveStream: boolean;
  env?: string;
}

interface PlaybackEvents {
  type: VideoPlayerEventType;
  handler: VideoPlayerEventCallback;
}

export default class OztamTracking {
  private OzTAMService: OzTAMService;

  private userId: string | null;

  private videoId: string;

  private duration: number;

  private isLiveStream: boolean;

  private adPodStartTime: number = -1;

  private currentTimeOverride: number = 0;

  private videoPlayer: VideoPlayer;

  private playbackStartTimestamp: number = 0;

  private isSnapBack: boolean = false;

  private events: PlaybackEvents[] = [];

  private beginSessionAfterPauseTime: number = 10 * 60 * 1000;
  private pauseTimestamp: number | undefined = undefined;

  static readonly generateSessionId = () => {
    function a() {
      return Math.floor((1 + Math.random()) * 65536).toString(16).substring(1);
    }

    return `${a() + a()}-${a()}-${a()}-${a()}-${a()}${a()}${a()}`;
  };

  constructor(sessionId: string, config: OztamTrackingConfig, videoPlayer: VideoPlayer, userId: string | null) {
    Logger.info('OztamTracking: Creating new instance');

    this.events = [
      { type: VideoPlayerEventType.SOURCE_LOADED, handler: this.onSourceLoaded as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.CONTENT_STARTED, handler: this.onContentStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_BREAK_STARTED, handler: this.onAdBreakStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_BREAK_FINISHED, handler: this.onAdBreakFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_STARTED, handler: this.onAdStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_FINISHED, handler: this.onAdFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.CUSTOM_EVENT, handler: this.onCustomEvent as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PLAYBACK_FINISHED, handler: this.onPlayFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PAUSED, handler: this.onPaused as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PLAYING, handler: this.onPlaying as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.BUFFERING_STARTED, handler: this.onBufferingStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.BUFFERING_FINISHED, handler: this.onBufferingFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.SEEK_STARTED, handler: this.onSeekStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.SEEK_FINISHED, handler: this.onSeekFinished as VideoPlayerEventCallback },
    ];

    const {
      publisherId, vendorVersion, env = 'prod', videoId,
      duration, isLiveStream,
    } = config;
    this.userId = userId;
    this.videoId = videoId;
    this.duration = duration;
    this.isLiveStream = isLiveStream;
    this.videoPlayer = videoPlayer;
    this.OzTAMService = new window.OzTAMService(
      publisherId,
      vendorVersion,
      env === 'prod',
      env !== 'prod',
      true,
    );
    this.OzTAMService.nextSessionId = sessionId;

    this.registerEvents();
  }

  private registerEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.on(event.type, event.handler);
    });
  };

  private generateMediaProperties = () => {
    const mediaProperties: any = {};
    mediaProperties[this.OzTAMService.PROP_ALT_MEDIA_ID] = this.videoId;

    if (this.userId) {
      mediaProperties[this.OzTAMService.PROP_DEMO1] = this.userId;
    }

    mediaProperties[this.OzTAMService.PROP_CONNECTION_TYPE] = get(window, 'navigator.connection.type', '');
    return mediaProperties;
  };

  // This function is internally called by OzTAM Service plugin's progress timer running every 60s
  // or when notable events occured (paused, stopped...) that needs a time update.
  private getCurrentTime = () => {
    let currentTime;

    if (this.adPodStartTime !== -1 || this.isSnapBack) {
      if (this.currentTimeOverride > 0) {
        currentTime = this.currentTimeOverride;
      } else {
        // ODWEB-1540: If it's an ad event, lets use the current stream time of the first ad event in the series
        // the next progress event will work out the rest and catchup when main content resumes.
        // This effectively reports the same current time (time when the first ad started) for the whole duration of the ad pod.
        currentTime = this.adPodStartTime;
      }
    } else {
      if (this.currentTimeOverride > 0) {
        // See comment in this.onSeekStarted()
        currentTime = this.currentTimeOverride;
      } else {
        currentTime = this.videoPlayer.getCurrentContentTime();
      }

      this.currentTimeOverride = 0;

      if (this.isLiveStream && isSafari) {
        currentTime = (this.playbackStartTimestamp / 1000) + currentTime;
      }
    }

    return Math.round(currentTime);
  };

  private onContentStarted = () => {
    // ODWEB-1306: Workaround to return timestamps as current position for Livestreams
    if (this.isLiveStream && this.playbackStartTimestamp === 0) {
      this.playbackStartTimestamp = new Date().getTime();
    }

    const mediaProperties = this.generateMediaProperties();

    this.OzTAMService.beginPlayback(
      this.videoId,
      // Sri's email on 09/02/2023: The URL was initially used to a different purpose (tracking etc.), but we can go without it now – not mandatory for certification.
      '',
      this.duration,
      this.getCurrentTime,
      mediaProperties,
      this.isLiveStream ? 'live' : 'vod',
    );
  };

  private onAdBreakStarted = () => {
    if (!this.isLiveStream) {
      this.adPodStartTime = this.videoPlayer.getCurrentContentTime();
      this.OzTAMService.haltProgress();
    }
  };

  private onAdStarted = () => {
    if (!this.isLiveStream) {
      this.onSeekFinished();
      this.OzTAMService.adBegin();
    }
  };

  private onAdFinished = () => {
    if (!this.isLiveStream) {
      this.OzTAMService.adComplete();
    }
  };

  private onAdBreakFinished = () => {
    if (!this.isLiveStream) {
      this.OzTAMService.resumeProgress();

      this.adPodStartTime = -1;
    }
  };

  private onPlayFinished = () => {
    this.OzTAMService.complete();
  };

  private onPaused = (event: VideoPlayerEventBase) => {
    const { issuer } = event;
    this.pauseTimestamp = new Date().getTime();

    // When seeking, Bitmovin also triggers a pause.
    // Since seeking will already halt progress we should not do it here.
    if (issuer !== 'ui-seek') {
      this.OzTAMService.haltProgress();
    }
  };

  private beginNewSession = () => {
    this.OzTAMService.generateNextSessionID();
    this.OzTAMService.startSession(
      this.videoId,
      '',
      this.duration,
      this.isLiveStream ? 'live' : 'vod',
    );
    const mediaProperties = this.generateMediaProperties();
    this.OzTAMService.beginPlayback(
      this.videoId,
      // The URL was initially used to a different purpose (tracking etc.), but we can go without it now – not mandatory for certification.
      '',
      this.duration,
      this.getCurrentTime,
      mediaProperties,
      this.isLiveStream ? 'live' : 'vod',
    );
  };

  private onPlaying = (event: VideoPlayerEventBase) => {
    const now = new Date().getTime();
    if (
      this.pauseTimestamp
      && now - this.pauseTimestamp >= this.beginSessionAfterPauseTime
    ) {
      this.beginNewSession();
      this.pauseTimestamp = undefined;
    }

    if (
      !event.isAd
      && this.OzTAMService.state !== this.OzTAMService.STATE_PLAYING
    ) {
      // During a seek, with Bitmovin, the PLAYING event usually happens before the SEEK_COMPLETE event
      // So we need to report a seek complete here as it needs to happen before the resume progress.
      if (this.OzTAMService.state === this.OzTAMService.STATE_SEEKING) {
        this.onSeekFinished();
      } else {
        this.OzTAMService.resumeProgress();
      }
    }
  };

  private onBufferingStarted = () => {
    this.OzTAMService.haltProgress();
  };

  private onBufferingFinished = () => {
    if (this.videoPlayer.isPlaying()) {
      this.OzTAMService.resumeProgress();
    }
  };

  private onSeekStarted = (event: VideoPlayerSeekEvent) => {
    const { issuer } = event;
    if (issuer !== 'snapBack' && issuer !== 'snapForward') {
      // ODW-498: Because Bitmovin implements the player differently in Safari, player.getCurrentTime() will not report
      // the time that we need when doing a seek. So for this event, we will get the position from the event object instead.
      // ODWEB-2814: Now using the position from the event object on every Seek events because on small seeks, Bitmovin seems
      // to process the seek too fast and we are getting a wrong partial progress duration.
      this.currentTimeOverride = event.positionContentTime;
      this.OzTAMService.seekBegin();
    }
  };

  private onSeekFinished = () => {
    if (this.OzTAMService.state === this.OzTAMService.STATE_SEEKING) {
      this.OzTAMService.seekComplete();
      if (this.videoPlayer.isPlaying()) {
        this.OzTAMService.resumeProgress();
      }
    }
  };

  private onCustomEvent = (event: VideoPlayerCustomEvent) => {
    const { eventName, positionContentTime } = event;
    switch (eventName) {
      case AdSnapBackEventType.AD_SNAP_BACK_STARTED:
        if (positionContentTime) {
          this.isSnapBack = true;
          this.currentTimeOverride = positionContentTime;
        }
        break;

      case AdSnapBackEventType.AD_SNAP_BACK_FINISHED:
        this.isSnapBack = false;
        this.currentTimeOverride = 0;
        break;

      case CastPlayerEventType.CAST_SESSION_STARTING:
        if (event.positionContentTime) {
          this.currentTimeOverride = event.positionContentTime;
        }
        this.OzTAMService.haltProgress();
        break;

      default:
        // nothing
        break;
    }
  };

  private onSourceLoaded = () => {
    Logger.info('OztamTracking: Starting new OzTAM session');

    this.OzTAMService.startSession(
      this.videoId,
      '',
      this.duration,
      this.isLiveStream ? 'live' : 'vod',
    );
  };

  public unload() {
    Logger.info('OztamTracking: Destroying OzTAMTracking plugin');

    this.OzTAMService.stop();

    this.events.forEach((event) => {
      this.videoPlayer.off(event.type, event.handler);
    });
  }
}
