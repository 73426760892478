import { debounce } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';
import { postProgressAsyncThunk } from '@@stores/UserActivityStore';
import { getIsLoggedIn } from '@@stores/UserStore';

import SaveProgressCore from './SaveProgressCore';

const SaveProgress: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const { videoPlayer, playbackStreamData, video } = props;
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const dispatch = useAppDispatch();

  const debouncedRecordProgress = useMemo(() => {
    return debounce((duration: number, position: number, entityId: string) => {
      if (isLoggedIn && duration > 600) {
        const seconds = Math.floor(position);
        const percent = Math.round((seconds * 100) / duration);

        dispatch(postProgressAsyncThunk({
          entityId,
          seconds,
          percent,
        }));
      }

    // The first call is always executed immediately,
    //   subsquent calls will wait for the timer.
    }, 1000, { leading: true });
  }, [dispatch, isLoggedIn]);

  const recordProgress = useCallback((duration: number, position: number, entityId: string) => {
    debouncedRecordProgress(duration, position, entityId);
  }, [debouncedRecordProgress]);

  useEffect(() => {
    let recorder: SaveProgressCore;

    if (video && video.isLiveStream === false && video.entityType !== 'CLIP') {
      recorder = new SaveProgressCore(videoPlayer, video, recordProgress);
    }

    return () => {
      if (recorder instanceof SaveProgressCore) {
        recorder.destroy();
      }
    };
  }, [videoPlayer, playbackStreamData, video, recordProgress]);

  // This plugin does not have a UI
  return null;
};

export default SaveProgress;
