import i18n from 'i18next';
import { FunctionComponent, useCallback } from 'react';

import DataLayer from '@@utils/DataLayer';

import ShowAtPlayTime from '../Utils/ShowAtPlayTime';
import type { VideoPlayerPluginProps } from '../VideoPlayerPlugin';
import SeekButton from './SeekButton';

const SkipIntro: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const { videoPlayer, playbackStreamData, video } = props;

  const skipIntroStart = playbackStreamData.playbackEvents.offerSkipOpeningCredits;
  const skipIntroEnd = playbackStreamData.playbackEvents.openingCreditsEnd;

  const handleShow = useCallback(() => {
    if (video) {
      DataLayer.events.videoNavigation(
        'skipIntro_display',
        video,
        videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            skipIntro: 'display',
          },
        },
      );
    }
  }, [video, videoPlayer]);

  const handleClick = useCallback(() => {
    if (video) {
      DataLayer.events.videoNavigation(
        'skipIntro_tap',
        video,
        videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            skipIntro: 'tap',
          },
        },
      );
    }
  }, [video, videoPlayer]);

  const seekFn = useCallback((seekTo: number): void => {
    videoPlayer.seek(seekTo);
  }, [videoPlayer]);

  if (typeof skipIntroStart !== 'number' || typeof skipIntroEnd !== 'number') {
    return null;
  }

  return (
    <ShowAtPlayTime
      key={video?.id}
      videoPlayer={videoPlayer}
      startTime={skipIntroStart}
      endTime={skipIntroEnd}
      onShow={handleShow}
      autoHideDelay={10}
      hideOnPause
    >
      <SeekButton seekFn={seekFn} seekTo={skipIntroEnd} onClick={handleClick}>
        {i18n.t('videoPlayer.skipIntro')}
      </SeekButton>
    </ShowAtPlayTime>
  );
};

export default SkipIntro;
