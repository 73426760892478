import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { get } from 'lodash';
import {
  ComponentProps, createElement,
  FunctionComponent, useCallback,
  useEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from '@@src/hooks/store';
import useQuery from '@@src/hooks/useQuery';
import useVideoPlayer from '@@src/hooks/useVideoPlayer';
import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import AdControls from '@@src/lib/VideoPlayerV2/plugins/AdControls/AdControls';
import AdOnPause from '@@src/lib/VideoPlayerV2/plugins/AdOnPause/AdOnPause';
import AdSnapBack from '@@src/lib/VideoPlayerV2/plugins/AdSnapBack/AdSnapBack';
import CastToggleButton from '@@src/lib/VideoPlayerV2/plugins/CastToggleButton/CastToggleButton';
import ConsumerAdvice from '@@src/lib/VideoPlayerV2/plugins/ConsumerAdvice/ConsumerAdvice';
import ContentMetadataBar from '@@src/lib/VideoPlayerV2/plugins/ContentMetadataBar/ContentMetadataBar';
import ConvivaAnalytics from '@@src/lib/VideoPlayerV2/plugins/ConvivaAnalytics/ConvivaAnalytics';
import ErrorCard from '@@src/lib/VideoPlayerV2/plugins/ErrorCards/ErrorCard';
import LoadingSpinner from '@@src/lib/VideoPlayerV2/plugins/LoadingSpinner/LoadingSpinner';
import OztamTracking from '@@src/lib/VideoPlayerV2/plugins/OztamTracking/OztamTracking';
import PictureInPictureOverlay from '@@src/lib/VideoPlayerV2/plugins/PictureInPictureOverlay/PictureInPictureOverlay';
import SaveProgress from '@@src/lib/VideoPlayerV2/plugins/SaveProgress/SaveProgress';
import SaveUserPreferences from '@@src/lib/VideoPlayerV2/plugins/SaveUserPreferences/SaveUserPreferences';
import SeekButtonsTracker from '@@src/lib/VideoPlayerV2/plugins/SeekButtonsTracker/SeekButtonsTracker';
import SkipIntro from '@@src/lib/VideoPlayerV2/plugins/SkipForward/SkipIntro';
import SkipRecap from '@@src/lib/VideoPlayerV2/plugins/SkipForward/SkipRecap';
import VideoPlaybackTracking from '@@src/lib/VideoPlayerV2/plugins/VideoPlaybackTracking/VideoPlaybackTracking';
import WatchPageV2Plugins from '@@src/pages/WatchPageV2Plugins';
import { generateFullPathFromLinkProps } from '@@src/routes';
import Logger from '@@src/utils/logger/Logger';
import { getSeries } from '@@stores/SeriesStore';
import grey from '@@styles/colors/grey';
import OnDemand from '@@types/OnDemand';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';
import { SSRFunctionComponent } from '@@types/ssr';

interface PluginMap {
  skipIntro: typeof SkipIntro;
  skipRecap: typeof SkipRecap;
  seekButtonsTracker: typeof SeekButtonsTracker;
  saveUserPreferences: typeof SaveUserPreferences;
  adSnapback: typeof AdSnapBack;
  adControls: typeof AdControls;
  adOnPause: typeof AdOnPause;
  loadingSpinner: typeof LoadingSpinner;
  videoPlaybackTracking: typeof VideoPlaybackTracking;
  consumerAdvice: typeof ConsumerAdvice;
  errorCard: typeof ErrorCard;
  castToggleButton: typeof CastToggleButton;
  saveProgress: typeof SaveProgress;
  contentMetadataBar: typeof ContentMetadataBar;
  pictureInPictureOverlay: typeof PictureInPictureOverlay;
}

type PluginKey = keyof PluginMap;

const useStyles = makeStyles<Theme>(() => {
  return createStyles({
    root: {
      backgroundColor: `${grey.black}`,
      position: 'fixed',
      right: 0,
      bottom: 0,
      top: 0,
      left: 0,
      fontSize: '1rem',
      '&::before': {
        content: '"Player v2"',
        position: 'absolute',
        zIndex: 9,
        top: 20,
        right: 20,
        textShadow: '0px 0px 10px #ffffff',
      },
    },
    playerBackgroundImage: {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
    },
    beforeUiContainer: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '100%',
    },
  });
});

interface Props {
  playbackStreamData: PlaybackStreamData | undefined
  closePath: string | undefined
  ozTamSessionId: string | undefined
  resumePosition: number | undefined
  userId: string | null
  video: OnDemand.Video | undefined
  setError: (error: Error | null) => void
  setVideoId: (videoId: string) => void
  adClickElementId?: string;
  autoPlay?: boolean;
}

const VideoPlayerContainer: SSRFunctionComponent<Props> = (props) => {
  const {
    playbackStreamData,
    resumePosition: lastProgress,
    setError,
    userId,
    ozTamSessionId,
    video,
    setVideoId,
    closePath,
    adClickElementId = 'adclick',
    autoPlay = undefined,
  } = props;
  const history = useHistory();
  const creditsTime = playbackStreamData?.playbackEvents?.closingCreditsBegin || undefined;
  const resumePosition = lastProgress && (!creditsTime || lastProgress < creditsTime) ? lastProgress : 0;

  const [videoPlayerContainer, setVideoPlayerContainer] = useState<HTMLDivElement>();
  const [videoElement, setVideoElement] = useState<HTMLVideoElement>();
  const [uiContainerElement, setUiContainerElement] = useState<HTMLDivElement>();

  const videoPlayerContainerRef = useCallback((el) => {
    setVideoPlayerContainer(el);
  }, []);

  const videoElementRef = useCallback((el) => {
    setVideoElement(el);
  }, []);

  const uiContainerElementRef = useCallback((el) => {
    setUiContainerElement(el);
  }, []);

  const beforeUiContainer = useRef<HTMLDivElement>(null);

  const oztamTracking = useRef<OztamTracking>();
  const convivaAnalytics = useRef<ConvivaAnalytics>();

  const [series, setSeries] = useState<OnDemand.TvSeries>();

  const dispatch = useAppDispatch();

  const { i18n: { language } } = useTranslation();
  const classes = useStyles(props);

  const videoPlayer = useVideoPlayer(videoPlayerContainer, videoElement, uiContainerElement, adClickElementId, autoPlay);

  const unloadPlayerAndPlugins = useCallback(async (_videoPlayer: VideoPlayer) => {
    if (_videoPlayer.isLoaded()) {
      await _videoPlayer.unload();
    } else {
      _videoPlayer.cancelLoad();
    }

    if (oztamTracking.current) {
      oztamTracking.current.unload();
    }

    if (convivaAnalytics.current) {
      convivaAnalytics.current.unload();
    }
  }, []);

  /**
   * Unload video player when the container unmounts
   */
  useEffect(() => {
    return () => {
      if (videoPlayer) {
        unloadPlayerAndPlugins(videoPlayer);
      }
    };
  }, [unloadPlayerAndPlugins, videoPlayer]);

  /**
   * User ID required before loading video
   */
  const loadVideo = useCallback(async () => {
    if (videoPlayer && playbackStreamData) {
      if (videoPlayer.isLoaded()) {
        await unloadPlayerAndPlugins(videoPlayer);
      }

      if (ozTamSessionId && playbackStreamData.oztam) {
        oztamTracking.current = new OztamTracking(ozTamSessionId, playbackStreamData.oztam, videoPlayer, userId);
      }

      const enableConviva = typeof process.env.BVAR_CONVIVA_CUSTOMER_KEY !== 'undefined' && typeof process.env.BVAR_CONVIVA_GATEWAY_URL !== 'undefined';
      if (enableConviva && video) {
        convivaAnalytics.current = new ConvivaAnalytics(videoPlayer, {
          assetName: playbackStreamData.reportingTitle,
          userId,
          video,
          config: {
            customerKey: process.env.BVAR_CONVIVA_CUSTOMER_KEY,
            debugLoggingEnabled: process.env.BVAR_CONVIVA_DEBUG === 'true',
            gatewayUrl: process.env.BVAR_CONVIVA_GATEWAY_URL,
          },
        });
      }

      const loadOptions = {
        resumePosition,
        backUrl: closePath,
      };

      const streamData = await videoPlayer.loadStreamHandler(
        playbackStreamData.mpxId,
        playbackStreamData.streamHandlerConfigs,
      );

      if (!streamData || videoPlayer?.isLoadCancelled()) {
        return;
      }

      convivaAnalytics?.current?.startSession(streamData.hlsUrl);

      videoPlayer.load(
        {
          ...playbackStreamData,
          ...streamData,
          userId,
        },
        loadOptions,
      ).catch((e) => {
        Logger.error(e);
        setError(e);
      });
    }
  }, [
    closePath, ozTamSessionId, playbackStreamData, resumePosition, setError, unloadPlayerAndPlugins,
    userId, video, videoPlayer,
  ]);

  useEffect(() => {
    if (userId !== undefined) {
      loadVideo()
        .catch((e) => {
          Logger.error('Failed instantiating the video player', { errorMessage: e.message });
        });
    }
  }, [loadVideo, userId]);

  useEffect(() => {
    if (video?.type === 'Episode' && video?.episodeData?.seriesSlug) {
      dispatch(getSeries(video.episodeData.seriesType, video.episodeData.seriesSlug, language))
        .then((_series) => {
          if (_series) {
            setSeries(_series);
          }
        });
    }
  }, [dispatch, language, video]);

  const handleVideoChange = useCallback((event: CustomEvent) => {
    const { detail: { videoId: newVideoId } } = event;
    videoPlayer?.pause('videochange');

    const path = generateFullPathFromLinkProps(
      {
        name: 'watch',
        params: {
          id: newVideoId,
        },
      },
      language,
    );

    window.history.replaceState(null, '', path);

    setVideoId(newVideoId);
  }, [language, setVideoId, videoPlayer]);

  useEffect(() => {
    const closeSignInHandler = () => {
      history.goBack();
    };

    // Go back to the previous page if login form is closed
    document.addEventListener('OdWebsite_CloseSignIn', closeSignInHandler);
    document.addEventListener('OdPlayerVideoChange', handleVideoChange as EventListener);

    return (() => {
      document.removeEventListener('OdWebsite_CloseSignIn', closeSignInHandler);
      document.removeEventListener('OdPlayerVideoChange', handleVideoChange as EventListener);
    });
  }, [history, handleVideoChange]);

  const plugins: Record<PluginKey, {
    component: PluginMap[PluginKey],
    // partial is not ideal because it will make all the Component props optional, but it's the only way to make the VideoPlayerPluginProps optional
    props?: Partial<ComponentProps<PluginMap[PluginKey]>>,
    portalContainer?: HTMLElement | null,
  }> = {
    contentMetadataBar: { component: ContentMetadataBar, portalContainer: beforeUiContainer.current },
    skipIntro: { component: SkipIntro },
    skipRecap: { component: SkipRecap },
    seekButtonsTracker: { component: SeekButtonsTracker },
    saveUserPreferences: { component: SaveUserPreferences },
    adSnapback: { component: AdSnapBack },
    adControls: { component: AdControls, portalContainer: beforeUiContainer.current, props: { adClickElementId } },
    loadingSpinner: { component: LoadingSpinner },
    videoPlaybackTracking: { component: VideoPlaybackTracking },
    consumerAdvice: { component: ConsumerAdvice },
    errorCard: { component: ErrorCard },
    castToggleButton: { component: CastToggleButton },
    saveProgress: { component: SaveProgress },
    adOnPause: { component: AdOnPause, portalContainer: beforeUiContainer.current },
    pictureInPictureOverlay: { component: PictureInPictureOverlay, portalContainer: beforeUiContainer.current },
  };

  return (

    <div ref={videoPlayerContainerRef}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video id="video-player" ref={videoElementRef}/>
      <div ref={beforeUiContainer} className={classes.beforeUiContainer}/>
      <div ref={uiContainerElementRef}/>
      {
        Object.keys(plugins).map((key) => {
          if (videoPlayer && playbackStreamData && typeof video !== 'undefined') {
            const pluginKey: PluginKey = key as PluginKey;
            const plugin = plugins[pluginKey];

            const element = createElement(plugin.component as FunctionComponent<any>, {
              key,
              videoPlayer,
              playbackStreamData,
              video,
              ...plugin.props,
            });

            if (plugin.portalContainer) {
              return createPortal(element, plugin.portalContainer);
            }

            return element;
          }

          return null;
        })
      }

      {
        videoPlayer && playbackStreamData && (
          <WatchPageV2Plugins
            videoPlayer={videoPlayer}
            playbackStreamData={playbackStreamData}
            video={video}
            series={series}
          />
        )
      }
    </div>
  );
};

export default VideoPlayerContainer;
